<template>
    <el-collapse v-model="activeName" accordion>
        <el-collapse-item title="Cum adaug categorii?" name="1">
          <iframe width="80%" height="700px" src="https://www.youtube.com/embed/McMiS9VRpC4?si=NxMHZZUTYrbAcTwJ&t=21">
          </iframe>
        </el-collapse-item>
        <el-collapse-item title="Trebuie sa tin cont de referinta / sku / identificator unic?" name="2">
            <div>Pentru a sincroniza corect produsele nu trebuie sa schimbati identificatorul unic. Pentru woocommerce acest identificator unic este referinta.</div>
            <div>Daca veti schimba acest atribut la produs, actualizarile nu vor mai functiona si se va importa ca un produs nou.</div>
        </el-collapse-item>
        <el-collapse-item title="Cum se vor sincroniza produsele?" name="3">
            <div>Daca ati mapat corect corect categoriile si caracteristicile, acestea se vor importa automat.</div>
        </el-collapse-item>
        <el-collapse-item title="Ce sunt metodele de plata?" name="4">
            <div>Fiecare comanda are o metoda de plata alocata (plata ramburs, plata cu cardul).</div>
            <div>Create metodele de plata pentru magazinul dumneavoastra sau puteti sa le folositi pe cele detectate (unde este cazul).</div>
        </el-collapse-item>
        <Versions />
    </el-collapse>
</template>

<script>
    import Versions from '@/views/integrari/magazin/shops/shared/Tabs/Info/Versions';

    export default {
        data() {
            return {
                activeName: '1'
            };
        },
        components: {
            Versions
        }
    };
</script>